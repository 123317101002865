import { QRCode, IProps } from 'react-qrcode-logo';
import React, { useContext } from 'react';
import { styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import { SearchCtx } from '@components/Search/CdsSearch';
import { useTracker, WidgetName } from '@utils/snowplowTracking';
import { Link, useMakeLink } from '@components/Link';
import { getWidgetDataById } from '@utils/widgets/widgetData';
import { useRuntimeSettings } from '@utils/config';
import { GA4TrackLinkClick } from '@utils/tracking';

type QrCodeProps = IProps & {
  label?: string;
  widgetName?: WidgetName;
  logoImage?: string;
};

export const QrCodeWidget = ({
  id,
  label,
  logoImage,
  widgetName,
  ...rest
}: QrCodeProps): JSX.Element => {
  const searchContext = useContext(SearchCtx);
  const makeLink = useMakeLink();
  const { trackLinkClick } = useTracker(QrCodeWidget.name);
  const {
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
  } = useRuntimeSettings();

  if (!id) {
    return <></>;
  }

  const query = searchContext?.data.search_text;
  const q = query?.replace(/\s/g, '_');
  const redirectUrl = makeLink({
    href: `/w/?id=${id}&q=${q}`,
    absoluteUrl: true,
  });

  return (
    <>
      <QrContainer spacing={16}>
        <WidgetContainer spacing={12}>
          <div
            aria-label={`QR-Code für ${getWidgetDataById(id)?.description}`}
            role="img"
          >
            <QRCode
              size={112}
              value={redirectUrl}
              fgColor={'#103D4B'}
              qrStyle="dots"
              logoPadding={1}
              logoImage={logoImage}
              {...rest}
            />
          </div>
          {label && (
            <QrCodeLink
              href={redirectUrl}
              size={7}
              weight={'medium'}
              target="_blank"
              onClick={() => {
                trackLinkClick?.(getWidgetDataById(id)?.url);
                enableGA4Tracking &&
                  GA4TrackLinkClick(
                    getWidgetDataById(id)?.url,
                    getWidgetDataById(id)?.description ?? '',
                  );
              }}
            >
              {label}
            </QrCodeLink>
          )}
        </WidgetContainer>
      </QrContainer>
    </>
  );
};

const QrContainer = styled(HStack, {
  alignItems: 'flex-start',
});

const WidgetContainer = styled(VStack, {
  alignItems: 'center',
});

export const QrCodeLink = styled(Link, {
  transition: 'transform 0.3s ease-in-out',
  '&:focus-visible:not([disabled])': {
    outline: '2px solid $text-standard',
    border: 'none',
    borderRadius: 4,
    outlineOffset: 4,
    transform: 'translate(4px, 0)',
  },
  '&:hover:not([disabled])': {
    transform: 'translate(4px, 0)',
  },
});
